import { css } from "@emotion/react";
import { secondaryColor } from "../../../config/theme";
import { Stack, Typography } from "@mui/material";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import { Form, Formik } from "formik";
import { AppLogoDivider, Button, ErrorMessage } from "../../../components";
import { TeamIdYupDemo } from "../../../utils/validation";
import { useState } from "react";
import { SearchTeam } from "./SearchTeam";

const initialValues = {
  team_id: "",
  generalErr: "",
};

interface IHighlightsDemoSectionStep1 {
  onNextStepClick: (values: { team_id: string; generalErr: string }) => void;
}

/**
 * HighlightsDemoSectionStep1
 *
 * The first step of the highlights demo section, which allows the user to search
 * for their FPL team and submit it to get the highlights.
 */
export const HighlightsDemoSectionStep1 = (
  props: IHighlightsDemoSectionStep1
) => {
  const [inputType, setInputType] = useState<"byName" | "byId">("byName");

  return (
    <Stack css={secondSectionContainerStyles}>
      <Stack css={teamDetailsContainerStyles}>
        <Typography variant="h1">
          {inputType === "byName"
            ? LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.TEAM_DETAILS
                .TEAM_NAME_TITLE
            : LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.TEAM_DETAILS
                .TITLE_TEAM_ID}
        </Typography>

        <Typography>
          {
            LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.TEAM_DETAILS
              .SUBTITLE
          }
        </Typography>

        <AppLogoDivider />

        <Formik
          initialValues={initialValues}
          validationSchema={TeamIdYupDemo}
          onSubmit={props.onNextStepClick}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form css={formStyles}>
              <SearchTeam
                inputType={inputType}
                handleItemClick={(item) => setFieldValue("team_id", item.entry)}
                onCantFindTeamClick={() => setInputType("byId")}
              />
              <ErrorMessage name="generalErr" />
              <Button
                button="gradient"
                type="submit"
                disabled={isSubmitting}
                css={btnStyles}
              >
                {
                  LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT
                    .TEAM_DETAILS.BTN
                }
              </Button>
            </Form>
          )}
        </Formik>
      </Stack>
    </Stack>
  );
};

const secondSectionContainerStyles = css`
  padding: 1.5rem;
  background-color: #222232;
  border-radius: 1.5rem;
  width: 100%;
`;

const teamDetailsContainerStyles = css`
  gap: 1rem;

  h1 {
    text-align: center;
    color: ${secondaryColor};
    font-size: clamp(2rem, 2.5vw, 2.5rem);
  }

  p {
    text-align: center;
  }
`;

const formStyles = css`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const btnStyles = css`
  max-height: none !important;
  min-height: 2.5rem !important;
`;
