export const VIEW_TEAM_PAGE = {
  SQUAD_VALUE: "Squad Value",
  MONEY_IN_THE_BANK: "Money in the bank",
  ALL_SEASON_TRANSFERS: "All season transfers",
  POINTS_THIS_GW: "Points this GW",
  OVERALL_POINTS: "Overall points",
  OVERALL_RANK: "Overall Rank",
  GW_RANK: "GW rank",
  RANK_DIFFERENCE: "Rank Difference",
  TRANSFERS: "Transfers",
  GW_POINTS: "GW Points",
  OVERALL_RANK_LABEL: "Overall Rank",
  VIEW_MORE: "View More >",
  PATH_SYMBOL: ">",
  POINTS_SUFFIX: " pts",
  TRANSFERS_COST_PREFIX: "( ",
  TRANSFERS_COST_SUFFIX: ")",
  NUMBER_OF_PLAYERS: "Number of Players",
  TRANSFERS_BTN: {
    TITLE: "Show transfers",
    SUBTITLE: "Click here to see more transfers",
  },
};

import tripleCapChip from "../assets/view-team/3xc.svg";
import freeHitChip from "../assets/view-team/free-hit.svg";
import wildCardChip from "../assets/view-team/wild-card.svg";
import bechBoostChip from "../assets/view-team/bench-boost.svg";

export const FPL_CHIPS: { [key: string]: { title: string; img?: string } } = {
  "3xc": { title: "Triple Captain chip played!", img: tripleCapChip },
  bboost: { title: "Bench Boost chip played!", img: bechBoostChip },
  wildcard: { title: "Wildcard chip played!", img: wildCardChip },
  freehit: { title: "Free Hit chip played!", img: freeHitChip },
};

export const tabsConstants = {
  fixture: "fixture",
  history: "history",
  ownership: "ownership",
};

export const EPL_PLAYER_SUMMARY = {
  TITLE: "Player Summary",
  BTN: "Show more",
  POSITION: {
    GK: "Goal Keeper",
    DEF: "Defender",
    MID: "Midfielder",
    ATT: "Attacker",
  },
  STATS_TABLE: {
    ROW_HEAD: "Stats",
    VALUE: "Values",
    POINTS: "Points",
  },
  TABLE_TITLES: {
    THIS_SEASON: "This Season",
    PAST_SEASON: "Previous Season",
    OWNERSHIP: "Ownership by managers",
  },
  TABS: [
    {
      label: "History",
      value: tabsConstants.history,
    },
    {
      label: "Fixtures",
      value: tabsConstants.fixture,
    },
    {
      label: "Ownership",
      value: tabsConstants.ownership,
    },
  ],
  TBC: {
    TITLE: "TBC",
    TOOL_TIP: "The date of the fixture is yet to be confirmed",
  },
};
