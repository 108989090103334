import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import fixtureIcon from "../../../assets/landing-page/fixture-icon.svg";
import quoteIcon from "../../../assets/landing-page/quote-icon.svg";
import card3Image from "../../../assets/landing-page/card-3-img.webp";
import { ExtraFeaturesCardContainer } from "./ExtraFeaturesCardContainer";
import { ExtraFeaturesIconContainer } from "./ExtraFeaturesIconContainer";
import { mq } from "../../../config/styles";

/**
 * A component that renders the third card in the Extra Features section of the
 * landing page. This card displays a fixture icon, a heading, and a subheading.
 */
export const ExtraFeaturesCard3 = () => {
  return (
    <ExtraFeaturesCardContainer>
      <Stack css={cardContentStyles}>
        <Stack>
          <ExtraFeaturesIconContainer icon={fixtureIcon} />
          <Stack css={titlesStyles}>
            <Typography variant="h1">
              {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD3.TITLE}
            </Typography>
            <Typography variant="h2">
              {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD3.SUBTITLE}
            </Typography>
          </Stack>
        </Stack>
        <img src={quoteIcon} css={quoteIconStylesTop} />
        <img src={quoteIcon} css={quoteIconStylesBottom} />
        <img src={card3Image} css={cardImageStyles} />
      </Stack>
    </ExtraFeaturesCardContainer>
  );
};

const cardContentStyles = css`
  height: 100%;
  width: 100%;
  padding: var(--spacing) 0 0 var(--spacing);
  gap: var(--spacing);
  flex-direction: row;
  height: clamp(15rem, 40vw, 23rem);
`;

const titlesStyles = css`
  flex: 1;
  margin-top: var(--spacing);
  max-width: 50%;

  h1 {
    font-size: clamp(0.875rem, 3vw, 1.8rem);
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  h2 {
    font-size: clamp(0.75rem, 2.5vw, 1.2rem);
    font-weight: 400;
    color: #98a2b3;
  }
`;

const quoteIconStylesTop = css`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: clamp(2.5rem, 5vw, 4rem);
`;

const quoteIconStylesBottom = css`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  width: clamp(2.5rem, 5vw, 4rem);
  rotate: 180deg;
`;

const cardImageStyles = css`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 75%;
  max-height: 100%;

  ${mq["md"]} {
    /* position: static; */
  }
`;
