import { useState } from "react";
import { ITeamInfo } from "../../../utils/hooks/useGetTeamInfo";
import { useEplPlayerSummary } from "../../../utils/hooks/useEplPlayerSummary";
import { useGetMiniLeagueMostHurtfulTable } from "../../../utils/hooks/useGetMiniLeagueMostHurtful";
import { IFplManager } from "../../../utils/types/types";
import { css } from "@emotion/react";
import { bgColor } from "../../../config/theme";
import { primaryGradient, transition_all } from "../../../config/styles";
import { ManagerListModal } from "../../mini-league-pages/components";
import { Stack, Typography } from "@mui/material";
import { EPL_PLAYER_SUMMARY } from "../../../constants/view-team-pages.constants";
import { Skeleton } from "../../../components";
import armBand from "../../../assets/mini-league/league-captains-icon.svg";
import check from "../../../assets/cool-feature/check.svg";
import skull from "../../../assets/cool-feature/skull.svg";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";

interface IManagerList {
  ownedBy: IFplManager[];
  playerName: string;
}

/** Renders the PLayer shirt and ownership section in the EPL player summary */
export const EplPlayerOwnership = (props: {
  eplPlayer: ITeamInfo["picks"][0] | null;
}) => {
  const [isCapList, setIsCapList] = useState(false);
  const [managersList, setManagersList] = useState<null | IManagerList>(null);
  const { selectedLeague } = useGlobalInfoContext();
  const { eplPlayersSummary } = useEplPlayerSummary();
  const selectedEplPlayerSummary = eplPlayersSummary?.find(
    (i) => i.element === props.eplPlayer?.element
  );

  const { mostHurtfulPlayerTableData } = useGetMiniLeagueMostHurtfulTable();

  const mostHurtful = mostHurtfulPlayerTableData?.find(
    (i) =>
      Number(i.owned_percentage.slice(0, 2)) >= 50 &&
      i.name.web_name === selectedEplPlayerSummary?.name
  );

  const handleCaptainCountClick = () => {
    if (
      selectedEplPlayerSummary &&
      selectedEplPlayerSummary.captained_by.length > 0
    ) {
      setIsCapList(true);
      setManagersList({
        ownedBy: selectedEplPlayerSummary.captained_by,
        playerName: selectedEplPlayerSummary.name,
      });
    }
  };

  const handleOwnedCountClick = () => {
    if (
      selectedEplPlayerSummary &&
      selectedEplPlayerSummary.owned_by.length > 0
    ) {
      setManagersList({
        ownedBy: selectedEplPlayerSummary.owned_by,
        playerName: selectedEplPlayerSummary.name,
      });
    }
  };

  const handleModalClose = () => {
    setIsCapList(false);
    setManagersList(null);
  };

  if (!selectedEplPlayerSummary) return <EplPlayerOwnershipSkeleton />;

  return (
    <Stack css={eplPlayerInfoContainerStyles}>
      <img
        src={
          selectedEplPlayerSummary.position === "GK"
            ? props.eplPlayer?.gk_shirt_link
            : props.eplPlayer?.shirt_link
        }
      />

      <Stack>
        <Typography variant="h1" css={playerNameStyles}>
          {selectedEplPlayerSummary.name}
        </Typography>
        <Typography css={positionStyles}>
          {EPL_PLAYER_SUMMARY.POSITION[selectedEplPlayerSummary.position]}
        </Typography>
        <Stack css={infoContainerStyles}>
          <span
            onClick={handleCaptainCountClick}
            css={countsIconsStyles(
              selectedEplPlayerSummary.captained_by.length > 0
            )}
          >
            <img src={armBand} />
            {selectedEplPlayerSummary.captained_by.length}
          </span>
          <span onClick={handleOwnedCountClick} css={countsIconsStyles(true)}>
            <img src={check} />
            {selectedEplPlayerSummary.owned_count} /{" "}
            {selectedEplPlayerSummary.league_size}
          </span>

          {mostHurtful && (
            <span
              onClick={handleOwnedCountClick}
              css={countsIconsStyles(false)}
            >
              <img src={skull} />
            </span>
          )}
          <span>{`in "${selectedLeague?.name}"`}</span>
        </Stack>
      </Stack>

      {managersList && (
        <ManagerListModal
          isCaptain={isCapList}
          open={managersList !== null}
          managersList={managersList.ownedBy}
          playerName={managersList.playerName}
          onClose={handleModalClose}
        />
      )}
    </Stack>
  );
};

const EplPlayerOwnershipSkeleton = () => (
  <Stack css={eplPlayerInfoContainerStyles}>
    <Skeleton
      css={css`
        background-color: #1c21307b;
        width: 80px;
        aspect-ratio: 1/1;
        border-radius: 9999px;
      `}
    />

    <Stack>
      <Skeleton
        css={css`
          background-color: #1c21307b;
          width: 180px;
          margin-bottom: 0.7rem;
        `}
      />
      <Skeleton
        css={css`
          background-color: #1c21307b;
          width: 80px;
        `}
      />
    </Stack>
  </Stack>
);

const eplPlayerInfoContainerStyles = css`
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  img {
    width: 60px;
  }
`;

const infoContainerStyles = css`
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
`;

const countsIconsStyles = (isClickable: boolean) => css`
  display: flex;
  gap: 0.4rem;
  line-height: 1;
  img {
    width: 16px;
  }

  ${isClickable &&
  css`
    padding: 0.4rem 0.6rem;
    border-radius: 9999px;
    background-color: ${bgColor}99;
    ${transition_all}
    cursor: pointer;
    :hover {
      background-color: ${bgColor};
    }
  `}
`;

const playerNameStyles = css`
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-weight: 700;
`;

const positionStyles = css`
  font-weight: 700;
  ${primaryGradient}
  width: fit-content;
  margin-bottom: 0.4rem;
  line-height: 1;
  background-clip: text;
  color: transparent;
`;
