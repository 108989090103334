import { css } from "@emotion/react";
import { IconButton, Stack, Typography } from "@mui/material";
import { AppTooltip, Skeleton } from "../../../components";
import arrow from "../../../assets/view-team/arrow.svg";
import { IEplPlayerSummary } from "../../../utils/hooks/useEplPlayerSummary";
import { useRef } from "react";

interface IEplPlayerSummaryStats {
  info: IEplPlayerSummary["info"] | undefined;
}

/**
 * Renders a component that displays a player's summary stats.
 * @param props - the player's info
 */

export const EplPlayerSummaryStats = (props: IEplPlayerSummaryStats) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onScrollClick = (direction: "left" | "right") => {
    if (containerRef.current) {
      const child = containerRef.current.children[0] as HTMLDivElement;
      if (direction === "right") {
        containerRef.current.scrollBy({
          left: -child.offsetWidth,
          behavior: "smooth",
        });
      } else {
        containerRef.current.scrollBy({
          left: child.offsetWidth,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <Stack css={containerStyles}>
      <IconButton onClick={() => onScrollClick("right")}>
        <img src={arrow} css={arrowStyles} />
      </IconButton>
      <Stack css={summarySectionContainerStyles} ref={containerRef}>
        {props.info
          ? props.info.map((info) => (
              <Stack key={info.display_name} css={infoStackStyles}>
                <Typography css={infoDisplayNameStyles}>
                  {info.display_name}
                  {info.description && (
                    <AppTooltip isSmall subTitle={info.description} />
                  )}
                </Typography>
                <Typography css={infoValueStyles}>{info.value}</Typography>
                {info.index && <Typography>{info.index}</Typography>}
              </Stack>
            ))
          : Array.from({ length: 4 }, (_, i) => (
              <Stack key={i} css={skeletonStackStyles}>
                <Typography css={skeletonDisplayNameStyles}>
                  <Skeleton />
                </Typography>
                <Typography css={skeletonValueStyles}>
                  <Skeleton />
                </Typography>
              </Stack>
            ))}
      </Stack>
      <IconButton onClick={() => onScrollClick("left")}>
        <img src={arrow} css={[arrowStyles, rotateStyles]} />
      </IconButton>
    </Stack>
  );
};

const containerStyles = css`
  flex-direction: row;
  align-items: center;
  background-color: #181829;
  border-radius: 0.5rem;
  box-shadow: 0 0 8px 0 #1b1b1b;
`;

const arrowStyles = css`
  padding: 0.5rem;
`;

const rotateStyles = css`
  rotate: 180deg;
`;

const summarySectionContainerStyles = css`
  flex: 1;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  padding-block: 1rem;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const infoStackStyles = css`
  min-width: 25%;
  align-items: center;
  justify-content: center;
  font-size: clamp(0.75rem, 1.5vw, 1rem);
  font-weight: 700;
  line-height: 12.57px;
  :not(:last-of-type) {
    border-right: 1px solid #dddedf33;
  }
  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;

const infoDisplayNameStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(0.75rem, 1.5vw, 1rem);
  font-weight: 700;
  line-height: 12.57px;
`;

const infoValueStyles = css`
  font-size: clamp(1rem, 2vw, 1.8rem) !important;
  font-weight: 700;
  line-height: 19.28px !important;
`;

const skeletonStackStyles = css`
  min-width: 25%;
  padding-inline: 0.4rem;
  display: block !important;
  :not(:last-of-type) {
    border-right: 1px solid #dddedf33;
  }
`;

const skeletonDisplayNameStyles = css`
  width: 100%;
  font-size: clamp(0.75rem, 1.5vw, 1rem);
  margin-bottom: 0.3rem;
`;

const skeletonValueStyles = css`
  width: 100%;
  font-size: clamp(1rem, 2vw, 1.8rem) !important;
`;
