import { css } from "@emotion/react";
import { getPointsColor } from "../../../utils/general.helpers";
import { Box } from "@mui/material";
import {
  EplPlayerTransfer,
  EplPlayerTransferSkeleton,
} from "./EplPlayerTransfer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { bgColor } from "../../../config/theme";
import { IEplPlayerTransferInfo } from "../../../utils/hooks/useGetLeagueTransfers";
import { Link, To } from "react-router-dom";

interface ITransferRow {
  element_in_info: IEplPlayerTransferInfo;
  element_out_info: IEplPlayerTransferInfo;
  elementOutTo: To;
  elementInTo: To;
}

/**
 * Displays a single transfer row, with the in and out players and an arrow inbetween.
 * used in TransfersCard and ManagerTransfer Page
 */
export const TransferRow = (props: ITransferRow) => {
  return (
    <Box css={transfersSummaryRowStyles}>
      <Link to={props.elementOutTo} css={transfersContainerStyles}>
        <EplPlayerTransfer
          eplPlayer={props.element_out_info}
          color={
            props.element_out_info.has_played
              ? getPointsColor(
                  props.element_out_info.gw_total_points,
                  props.element_in_info.gw_total_points
                )
              : "grey"
          }
        />
      </Link>
      <Box css={transfersSummaryArrowStyles}>
        <ArrowForwardIcon />
      </Box>
      <Link to={props.elementInTo} css={transfersContainerStyles}>
        <EplPlayerTransfer
          eplPlayer={props.element_in_info}
          color={
            props.element_in_info.has_played
              ? getPointsColor(
                  props.element_in_info.gw_total_points,
                  props.element_out_info.gw_total_points
                )
              : "grey"
          }
        />
      </Link>
    </Box>
  );
};

export const TransferRowSkeleton = () => {
  return (
    <Box css={transfersSummaryRowStyles}>
      <EplPlayerTransferSkeleton />

      <Box
        css={[
          transfersSummaryArrowStyles,
          css`
            & > * {
              background: ${bgColor};
              color: rgba(255, 255, 255, 0.1);
            }
          `,
        ]}
      >
        <ArrowForwardIcon />
      </Box>

      <EplPlayerTransferSkeleton />
    </Box>
  );
};

const transfersSummaryRowStyles = css`
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
`;

const transfersSummaryArrowStyles = css`
  flex: 0.25;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    width: 100%;
    max-width: clamp(2rem, 5vw, 4rem);
    padding: 0.2rem;

    background: #717171;
    border-radius: 9999px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 4 4 0 rgba(0, 0, 0, 0.25), 0 -4 4 0 rgba(0, 0, 0, 0.25);
  }
`;

const transfersContainerStyles = css`
  flex: 1;
  display: block;
  overflow: hidden;
`;
