import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";

interface IOpponentCellProps {
  opponent_team_logo: string;
  opponent_team_name: string;
  was_home: boolean;
  team_h_score?: number;
  team_a_score?: number;
}

/** JSX for displaying opponent information in EPL Player Summary table.*/
export const OpponentCell: React.FC<IOpponentCellProps> = ({
  opponent_team_logo,
  opponent_team_name,
  was_home,
  team_h_score,
  team_a_score,
}) => (
  <Stack css={opponentTeamStyle}>
    <img src={opponent_team_logo} alt="opponent-logo" />
    <Typography>
      {`${opponent_team_name} (${was_home ? "H" : "A"})`}{" "}
      {team_a_score != null && team_h_score != null
        ? `${team_h_score} - ${team_a_score}`
        : ""}
    </Typography>
  </Stack>
);

const opponentTeamStyle = css`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
  img {
    height: 25px;
  }

  p {
    font-size: inherit;
  }
`;
