import { GridColDef } from "@mui/x-data-grid";
import { IEplPlayerSummary } from "../hooks/useEplPlayerSummary";
import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { getEplPLayerSummaryFixtureDates } from "../dateHandlers";
import { IEplPlayerOwnedSince } from "../../pages/view-team-pages/components/EplPlayerSummaryOwnershipTable";
import { AppTableTeamCell, RankIndicator, Skeleton } from "../../components";
import capBadge from "../../assets/mini-league/league-captains-icon.svg";
import { columnNumber } from "../muiTableColumns";
import {
  FixtureDifficultyCell,
  GameStatusCell,
  OpponentCell,
  TBCTableCellEPLPlayerSummaryFixtures,
} from "../../components/table-cells";
import { TotalPointsCell } from "../../components/table-cells/TotalPointsCell";

export const eplPlayerSummaryCoreColumns: GridColDef<
  IEplPlayerSummary["summary"]["history"][0]
>[] = [
  {
    width: 8,
    field: "starts",
    headerName: "ST",
    description: "Games(s) Started",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "minutes",
    headerName: "MP",
    description: "Minutes Played",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "goals_scored",
    headerName: "GS",
    description: "Goals Scored",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "assists",
    headerName: "A",
    description: "Assists",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "expected_goals",
    headerName: "xG",
    description: "Expected Goals",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "expected_assists",
    headerName: "xA",
    description: "Expected Assists",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "expected_goal_involvements",
    headerName: "xGI",
    description: "Expected Goal Involvements",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "clean_sheets",
    headerName: "CS",
    description: "Clean Sheets",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "goals_conceded",
    headerName: "GC",
    description: "Goals Conceded",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "expected_goals_conceded",
    headerName: "xGC",
    description: "Expected Goals Conceded",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "own_goals",
    headerName: "OG",
    description: "Own Goals",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "penalties_saved",
    headerName: "PS",
    description: "Penalties Saved",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "penalties_missed",
    headerName: "PM",
    description: "Penalties Missed",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "yellow_cards",
    headerName: "YC",
    description: "Yellow Cards",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "red_cards",
    headerName: "RC",
    description: "Red Cards",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "saves",
    headerName: "S",
    description: "Saves",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "bonus",
    headerName: "BP",
    description: "Bonus",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "bps",
    headerName: "BPS",
    description: "Bonus Points System",
    headerAlign: "center",
    align: "center",
  },

  {
    width: 8,
    field: "influence",
    headerName: "I",
    description: "Influence",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "creativity",
    headerName: "C",
    description: "Creativity",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "threat",
    headerName: "T",
    description: "Threat",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "ict_index",
    headerName: "II",
    description: "ICT Index",
    headerAlign: "center",
    align: "center",
  },

  //   {
  //     width: 8,
  //     field: "transfers_in",
  //     headerName: "TI",
  //     description: "Transfers In",
  // headerAlign:"center", align:"center"  },
  //   {
  //     width: 8,
  //     field: "transfers_out",
  //     headerName: "TO",
  //     description: "Transfers Out",
  // headerAlign:"center", align:"center"  },
  //   { width: 8,field: "element", headerName: "E", description: "ElementheaderAlign:"center", align:"center"" },
  //   { width: 8,field: "fixture", headerName: "F", description: "FixtureheaderAlign:"center", align:"center"" },
  //   {
  //     width: 8,
  //     field: "opponent_team",
  //     headerName: "OT",
  //     description: "Opponent Team",
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   { width: 8,field: "was_home", headerName: "WH", description: "Was HomeheaderAlign:"center", align:"center"" },
  //   {
  //     width: 8,field: "kickoff_time",
  //     headerName: "KT",
  //     description: "Kickoff Time",
  // headerAlign:"center", align:"center"  },
  //   {
  //     width: 8,
  //     field: "team_h_score",
  //     headerName: "THS",
  //     description: "Team Home Score",
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     width: 8,
  //     field: "team_a_score",
  //     headerName: "TAS",
  //     description: "Team Away Score",
  //     headerAlign: "center",
  //     align: "center",
  //   },
];

// eplPlayerSummaryHistoryColumns now using extracted components
export const eplPlayerSummaryHistoryColumns: GridColDef<
  IEplPlayerSummary["summary"]["history"][0]
>[] = [
  {
    width: 8,
    field: "gw",
    headerName: "GW",
    description: "Game Week",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 120,
    field: "fixture",
    headerName: "OPP",
    description: "Opponent",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <OpponentCell
        opponent_team_logo={row.opponent_team_logo}
        opponent_team_name={row.opponent_team_name}
        was_home={row.was_home}
        team_h_score={row.team_h_score}
        team_a_score={row.team_a_score}
      />
    ),
  },
  {
    width: 20,
    field: "game_status",
    headerName: "",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <GameStatusCell
        was_home={row.was_home}
        team_h_score={row.team_h_score}
        team_a_score={row.team_a_score}
      />
    ),
  },
  {
    width: 8,
    field: "total_points",
    headerName: "Pts",
    description: "Points",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <TotalPointsCell total_points={row.total_points} />
    ),
  },
  ...eplPlayerSummaryCoreColumns,
  {
    width: 80,
    field: "transfers_balance",
    headerName: "NT",
    description: "Transfer Balance",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 80,
    field: "selected",
    headerName: "SB",
    description: "Selected",
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "value",
    headerName: "£",
    description: "Value",
    renderCell: ({ row }) => `£${row.value}`,
    headerAlign: "center",
    align: "center",
  },
];

// Fixture column refactor using extracted components
export const eplPlayerSummaryFixture: GridColDef<
  IEplPlayerSummary["summary"]["fixtures"][0]
>[] = [
  {
    width: 120,
    flex: 1,
    field: "kickoff_time",
    headerName: "Date",
    renderCell: ({ row }) => getEplPLayerSummaryFixtureDates(row.kickoff_time),
  },
  {
    width: 8,
    flex: 1,
    field: "event_name",
    headerName: "GW",
    renderCell: ({ row }) =>
      row.event_name ? (
        row.event_name.split(" ")[1]
      ) : (
        <TBCTableCellEPLPlayerSummaryFixtures />
      ),
    headerAlign: "center",
    align: "center",
  },
  {
    width: 120,
    flex: 1.5,
    field: "opponent",
    headerName: "OPP",
    description: "Opponent",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <OpponentCell
        opponent_team_logo={row.opponent_team_logo}
        opponent_team_name={row.opponent_team_name}
        was_home={row.is_home}
      />
    ),
  },
  {
    width: 8,
    flex: 1,
    field: "difficulty",
    headerName: "FDR",
    description: "Fixture difficulty rating",
    renderCell: ({ row }) => (
      <FixtureDifficultyCell difficulty={row.difficulty} />
    ),
    headerAlign: "center",
    align: "center",
  },
];

export const eplPlayerHistoryPast: GridColDef<
  IEplPlayerSummary["summary"]["history_past"][0]
>[] = [
  {
    width: 60,
    field: "season_name",
    headerName: "Season",
    description: "Season",
    headerAlign: "center",
    align: "center",
  },
  ...(eplPlayerSummaryCoreColumns.map((i) => ({ ...i, width: 5 })) as any),
  {
    width: 8,
    field: "start_cost",
    headerName: "£S",
    description: "Price at the start of season",
    renderCell: ({ row }) => `£${row.start_cost / 10}`,
    headerAlign: "center",
    align: "center",
  },
  {
    width: 8,
    field: "end_cost",
    headerName: "£E",
    description: "Price at the end of season",
    renderCell: ({ row }) => `£${row.end_cost / 10}`,
    headerAlign: "center",
    align: "center",
  },
];

export const eplPlayerSummaryOwnedSince: GridColDef<IEplPlayerOwnedSince>[] = [
  {
    ...columnNumber,
    renderCell: ({ row }: { row: IEplPlayerOwnedSince }) => (
      <Stack
        height={"100%"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <RankIndicator rank={row.rank} lastRank={row.last_rank} />
        {row.rank_sort}
      </Stack>
    ),
  },
  {
    field: "is_captain",
    width: 20,
    headerName: "",
    sortable: false,
    renderCell: ({ row }) =>
      row.is_captain ? (
        <Stack height={"100%"} justifyContent={"center"} alignItems={"center"}>
          <img src={capBadge} />
        </Stack>
      ) : null,
  },
  {
    field: "team",
    headerName: "Manager name",
    flex: 1,
    sortable: false,
    renderCell: (value) => (
      <AppTableTeamCell
        mangerName={value.row.entry_name}
        teamName={value.row.player_name}
      />
    ),
  },
  {
    field: "owned_since",
    headerName: "Owned Since",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <Stack
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingBlock={"0.5rem"}
      >
        {row.owned_since ? (
          <Typography
            fontWeight={700}
            fontSize={"inherit"}
          >{`GW${row.owned_since}`}</Typography>
        ) : (
          <Skeleton
            css={css`
              background-color: #1a1a2488;
            `}
          />
        )}
      </Stack>
    ),
  },
];
