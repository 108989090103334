export const MINI_LEAGUE_PAGE = {
  META: {
    TITLE: "Mini League",
  },
  TITLE: "Mini League",
  TABLE: {
    TITLE: "Standings",
    BTN_TITLE: "Show Previous Captains",
    SMALL_BTN_TITLE: "Prev. Captains",
  },
  CAPTAINS: {
    META: {
      TITLE: "League Captains",
    },
    CARD: {
      TITLE: "Most Captained",
      BTN: "Managers that captained him",
    },
    TABLE: {
      TITLE: "League Captains",
      SUB_TITLE:
        "List of how many times the most popular captains were picked by each manager",
    },
  },
  MOST_HURTFUL: {
    META: {
      TITLE: "Most Hurtful",
    },
    TABLE: {
      TITLE: "Most Hurtful",
      SUB_TITLE:
        "Players you don’t own that are the most popular with your selected league.",
    },
    CARD: {
      TITLE: "Most Hurtful",
      CAPTAIN_DESCRIPTION: (
        ownedRatio: string,
        ownedPercentage: number | string
      ) =>
        `Captained by ${ownedRatio} of the league (${ownedPercentage} of league)`,
      MOST_HURTFUL_DESCRIPTION: (
        ownedRatio: string,
        ownedPercentage: number | string
      ) =>
        `Owned by ${ownedRatio} of the league (${ownedPercentage}) of league`,
      BTN: "Managers that own him",
    },
  },
  NEW_ENTIRES: {
    META: {
      TITLE: "New Entries",
    },
    TABLE: { TITLE: "New Entries" },
  },
};
