import { css } from "@emotion/react";
import { secondaryColor } from "../../config/theme";
import { Stack } from "@mui/material";
import {
  circularStyleMuiDataGridPlayerSummary,
  stackCenteredStyleMuiDataGrid,
} from "../../config/styles";

interface IGameStatusCellProps {
  was_home: boolean;
  team_h_score: number;
  team_a_score: number;
}

/** JSX for displaying match result status in EPL Player Summary table. */
export const GameStatusCell: React.FC<IGameStatusCellProps> = ({
  was_home,
  team_h_score,
  team_a_score,
}) => {
  let status = { color: "#D8191F", game: "L" };

  if (team_a_score === team_h_score) {
    status = { color: "#76766F", game: "D" };
  } else if (
    (was_home && team_h_score > team_a_score) ||
    (!was_home && team_a_score > team_h_score)
  ) {
    status = { color: secondaryColor, game: "W" };
  }

  return (
    <Stack css={stackCenteredStyleMuiDataGrid}>
      <Stack
        css={css`
          ${circularStyleMuiDataGridPlayerSummary}
          background-color: ${status.color};
        `}
      >
        {status.game}
      </Stack>
    </Stack>
  );
};
